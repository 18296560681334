<template>
  <v-dialog v-model="modalValue" :max-width="maxWidth" :scrollable="scrollable" :persistent="persistent" content-class="generic-modal__dialog">
    <v-card>
      <v-card-title class="generic-modal__header-container">
        <div class="generic-modal__title-container">
          <div class="generic-modal__title">
            <div v-if="hasHeaderIcon" class="generic-modal__icon">
              <div class="generic-modal__icon-content">
                <slot name="header-icon" />
              </div>
            </div> {{ title }}
          </div>
          <div>
            <v-icon v-if="hasClose" class="generic-modal__close" @click="close">mdi-close</v-icon>
          </div>
        </div>
      </v-card-title>
      <v-card-text class="generic-modal__content">
        <slot />
      </v-card-text>
      <v-card-actions
        v-if="hasActions"
        :class="{
          'generic-modal__actions': true,
          'justify-content-space-between': hasLeftActions,
          'justify-content-end': !hasLeftActions,
        }"
      >
        <slot name="left-actions" />
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'GenericModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: null,
    },
    hasClose: {
      type: Boolean,
      default: true
    },
    onClose: {
      type: Function,
      default: null,
    }
  },
  computed: {
    modalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    hasHeaderIcon() {
      return this.$slots['header-icon'];
    },
    hasLeftActions() {
      return this.$slots['left-actions'];
    },
    hasActions() {
      return this.hasLeftActions || this.$slots['actions'];
    },
  },
  methods: {
    close() {
      if (this.onClose) {
        this.onClose();
      } else {
        this.$emit('input', false);
      }
    },
  },
};
</script>

<style lang="scss">
$white: #ffffff;
$grey-light: #f1f1f1;
$primary-color: var(--v-primary-base);

.justify-content-end {
  justify-content: end;
}
.justify-content-space-between {
  justify-content: space-between;
}

.generic-modal {
  &__dialog {
    margin: 0 8px;
  }

  &__header-container {
    background-color: $grey-light;
    padding-left: rem(8) !important;
    padding-right: rem(8) !important;

    @media only screen and (min-width: 768px) {
      padding-left: rem(32) !important;
      padding-right: rem(32) !important;
    }
  }

  &__title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 500;
  }

  &__icon {
    position: relative;
    display: none;

    @media only screen and (min-width: 992px) {
      display: grid;
      place-content: center;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      background-color: $primary-color;
      margin-right: 1rem;
    }
  }

  &__icon-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__close {
    font-size: 1.25rem !important;
  }

  &__steps-counter {
    margin-left: 0.25rem;
    font-weight: 100;
  }

  &__content {
    padding-left: rem(8) !important;
    padding-right: rem(8) !important;

    @media only screen and (min-width: 768px) {
      padding-left: rem(32) !important;
      padding-right: rem(32) !important;
    }
  }

  &__actions {
    padding-left: rem(8) !important;
    padding-right: rem(8) !important;
    padding-bottom: rem(8) !important;
    width: 100%;
    display: flex;
    flex-direction: row;

    @media only screen and (min-width: 768px) {
      padding-left: rem(32) !important;
      padding-right: rem(32) !important;
      padding-bottom: rem(32) !important;
    }

    @media only screen and (max-width: 768px) {
      position: sticky;
      bottom: 0;
      background: white;
    }
  }
}
</style>
